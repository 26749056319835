import "core-js/modules/es6.array.find.js";
import "core-js/modules/es6.array.map.js";
export default defineComponent({
  name: 'VentureNumberOfImpacts',
  props: {
    assessment: Object,
    stage: String
  },
  setup: function setup(props) {
    var outcomes = computed(function () {
      var _props$assessment;
      var outcomeStage = (_props$assessment = props.assessment) === null || _props$assessment === void 0 ? void 0 : _props$assessment.outcomesByStages.find(function (e) {
        return e.stage === props.stage;
      });
      if (!outcomeStage) return [];
      return outcomeStage.outcomes.map(function (id) {
        var _props$assessment2;
        return (_props$assessment2 = props.assessment) === null || _props$assessment2 === void 0 ? void 0 : _props$assessment2.outcomes[id];
      });
    });
    return {
      outcomes: outcomes
    };
  }
});